/* style.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  
  nav {
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 36px 20px 46px;
  }
  nav .logobox {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  nav .logobox .text {
    font-size: 20px;
    font-weight: 400;
    padding-top: 10px;
  }
  /* nav .page_name {
    font-size: 15px;
    font-weight: 400;
    color: #1d1d1f;
    cursor: pointer;
  } */
  nav .page_name:hover {
    color: gray;
    transition: all 0.5s ease;
  }
  nav .button button {
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    background-color: #fa0060d3;
    border: 1px solid #d9d9d9;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
  }

  
  div.mainbody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div.mainbody h1 {
    padding: 30px 0px;
    color: #1d1d1f;
    font-size: 33px;
    font-weight: 400;
    text-align: center;
  }
  div.mainbody h1 span {
    color: #fa0060;
    font-weight: 700;
  }
  div.mainbody h3 {
    font-size:21px;
    font-weight: 400;
    padding-bottom: 40px;
  }
  div.mainbody video {
    width: 500px;
    height: 250;
    border: 1px solid #d9d9d9;
    border-radius: 36px;
  }
  div.footer {
    font-size: 13px;
    font-weight: 400;
    color: #1d1d1f;
    width: 100%;
    text-align: center;
    padding: 30px 0px;
  }
  
  @media (max-width: 800px) {
    nav .logobox .text {
      font-size: 20px;
      padding-top: 5px;
    }
    nav .logobox .logo img {
      width: 65px;
    }
    nav .page_name {
      font-size: 15px;
    }
    nav .button button {
      font-size: 14px;
      padding: 5px 10px;
    }
    nav {
      padding: 18px 26px 10px 36px;
    }
    div.footer {
      font-size: 15px;
    }
    div.mainbody h1 {
      padding: 30px 0px;
      font-size: 33px;
    }
    div.mainbody h3 {
      font-size: 21px;
      padding-bottom: 30px;
    }
    div.mainbody video {
      width: 550px;
      height: 300px;
    }
  }
  @media (max-width: 600px) {
    nav .logobox .text {
      font-size: 15px;
      padding-top: 3px;
    }
    nav .logobox .logo img {
      width: 45px;
    }
    nav .page_name {
      font-size: 10px;
    }
    nav .button button {
      font-size: 9px;
      padding: 3px 8px;
    }
    nav {
      padding: 15px 20px 8px 30px;
    }
    div.footer {
      font-size: 10px;
    }
    div.mainbody h1 {
      font-size: 25px;
      padding-left: 10px;
      padding-right: 10px;
    }
    div.mainbody h3 {
      font-size: 15px;
    }
    div.mainbody video {
      width: 90%;
      height: 200px;
    }
  }
  